.contactless-message {
  height: 45px;
  padding-left: 0;
  padding-right: 0;
  background: red;
  position: sticky;
  top: 0;
  border-bottom: solid #ffffff 2px;
  border-top: solid #ffffff 2px;
  z-index: 999;
  cursor: pointer;

  .marquee {
    width: 100%;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;

    h3
    {
      display: inline-block;
      padding-left: 100%;
      color: #fff;
      margin-top: 2px;
      margin-bottom: 0;
      animation: marquee 30s linear infinite;
      text-transform: uppercase;
    }

    #secondmessage {
      animation-delay: 15s;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

@keyframes marquee {
  0%   {transform: translate(0, 0);}
  100% {transform: translate(-100%, 0);}
}